import {Order} from "../types/GeneralTypes";

import _ from 'lodash';
import {SaveStatus} from "../types/GeneralTypes";

// Removes all special characters in search String
export function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

// Sort Comparator
function descendingComparator<T>(a: T, b: T, orderBy: keyof T, secondaryKey?: keyof T, secondaryOrder?: Order) {
    if (_.get(b, orderBy) === 0 && _.get(a, orderBy) === null) {
        return 1;
    }
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
        return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
        return 1;
    }
    if (secondaryKey && orderBy !== secondaryKey) {
        const secondary = (secondaryOrder === 'desc') ? 1 : -1;
        return (_.get(a, secondaryKey) !== null && _.get(b, secondaryKey) < _.get(a, secondaryKey)) ? (-1 * secondary) : (1 * secondary);
    } else {
        return 0;
    }
}

// Determine sort function by direction
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
    secondarySort?: Key,
    secondaryOrder?: Order
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, secondarySort, secondaryOrder)
        : (a, b) => -descendingComparator(a, b, orderBy, secondarySort, secondaryOrder);
}

// Returns Save Status Coloring for Table Cells
export function generateStatusColor(status: SaveStatus) {
    switch (status) {
        case SaveStatus.EDITED:
            return {
                bgcolor: 'warning.lighter'
            }

        case SaveStatus.NEW:
            return {
                bgcolor: 'success.lighter'
            }

        default:
            return {}
    }
}

// Checks objects if defined attributes have changed.
// Limitations for date checks
export function checkObjectChanged(prevObj: any, newObj: any, checkAttr: Array<string>): boolean {
    let changed = false;
    checkAttr.forEach(attr => {
        if (prevObj[attr] !== newObj[attr]) changed = true;
    })

    return changed;
}