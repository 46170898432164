import {format, isBefore, isSameDay, isValid} from "date-fns";


// Checks if string is a valid date
export function checkValidDate(date: string | Date | null): boolean {
    if (typeof date === 'string') date = new Date(date);
    return isValid(date);
}

// Checks if date one is the same as date two
export function checkDateSame(date: string | number | Date, dateAfter: string | number | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isSameDay(date, dateAfter);
}

// Checks if date one is before date two
export function checkDateBefore(date: string | number | Date, dateAfter: string | number | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isBefore(date, dateAfter);
}

// Checks if date one is same or before date two
export function checkDateSameOrBefore(date: string | number | Date, dateAfter: string | number | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isBefore(date, dateAfter) || isSameDay(date, dateAfter);
}

// Check date between or same as the last
export function checkDateBetween(date: string | number | Date, before: string | number | Date, after: string | number | Date) {
    if (typeof date === 'string') date = new Date(date);
    if (typeof before === 'string') before = new Date(before);
    if (typeof after === 'string') after = new Date(after);

    return ((isBefore(date, after) || isSameDay(date, after)) && (isBefore(before, date) || isSameDay(date, before)))
}

// Formats date to string
export function formatDate(date: Date | string | number, dateFormat = 'dd-MM-yyyy'): string {
    try {
        if (!!date && date !== '') {
            if (typeof date === 'string') {
                return format(new Date(date), dateFormat)
            } else {
                return format(date, dateFormat)
            }
        } else {
            return '';
        }
    } catch (e) {
        return 'N/A'
    }
}

// Add days to date
export function addDays(date: Date | string | number, days: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function subtractOneMonth(date: string | Date) {
    date = new Date(date);
    const currentMonth = date.getMonth();
    const newDate = new Date(date);

    // Step 1: Set the date to the 1st of the current month
    newDate.setDate(1);

    // Step 2: Subtract one month
    newDate.setMonth(currentMonth - 1);

    // Step 3: Adjust the date if the resulting month has fewer days
    const maxDaysInPrevMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        0
    ).getDate();

    newDate.setDate(Math.min(date.getDate(), maxDaysInPrevMonth));

    return newDate;
}


// Date sort comparator
export function dateSortComparator(a: Date | string | number, b: Date | string | number) {
    if (checkDateBefore(a, b)) return -1;
    else if (checkDateBefore(b, a)) return 1;
    else return 0
}

// Find next Day of the Week
export function findDayOfWeek(date: string | Date, dayOfWeek: number): Date {
    date = new Date(date);
    // sets day to Start of the week
    date.setDate(date.getDate() - date.getDay() + 1);
    // finds next day
    date.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return date;
}

// Find next Day of the Week
export function findNextDayOfTheWeek(date: string | Date, dayOfWeek: number): Date {
    date = new Date(date);
    date.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return date;
}


// finds the last day of the month
export function findlastDayOfMonth(date: string | Date): Date {
    date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

// Finds last week day of month
export function findLastBusDayMonth(date: string | Date): Date {
    date = new Date(date);
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    while (lastDate.getDay() < 1 || lastDate.getDay() > 5) {
        lastDate.setDate(lastDate.getDate() - 1);
    }

    return lastDate;
}

// Find last day of month
export function getLastDayPreviousMonth(date: string | Date, allowSame: boolean = true): Date {
    let newDate = new Date(date);
    // Gets last day of month
    newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);


    // if newDate is after date and not allow same is not allowed subtract 1 month
    if (((allowSame && checkDateBefore(date, newDate)) || (!allowSame && checkDateSameOrBefore(date, newDate)))) {
        newDate = subtractOneMonth(newDate)
        newDate = new Date(newDate.getFullYear(), newDate.getMonth()+1, 0);
    }

    return newDate
}

// Find last Business Day of Previous month
export function getLastBusinessDayPreviousMonth(date: string | Date, allowSame: boolean = true): Date {
    let newDate = new Date(date);
    // Gets last day of month
    newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);

    // If weekend revert to Friday
    if (newDate.getDay() === 6) {
        newDate = addDays(newDate, -1);
    } else if (newDate.getDay() === 0) {
        newDate = addDays(newDate, -2)
    }

    // if newDate is after date and not allow same is not allowed subtract 1 month
    if (((allowSame && checkDateBefore(date, newDate)) || (!allowSame && checkDateSameOrBefore(date, newDate)))) {
        newDate = subtractOneMonth(newDate)
        newDate = new Date(newDate.getFullYear(), newDate.getMonth()+1, 0);

        // If weekend revert to Friday
        if (newDate.getDay() === 6) {
            newDate = addDays(newDate, -1);
        } else if (newDate.getDay() === 0) {
            newDate = addDays(newDate, -2)
        }
    }

    return newDate
}

// Coverts DMY date format with "=" to a Date string
export function convertDMYDate(date: string) {
    const dateParts = date.split('-');

    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]).toDateString()
}