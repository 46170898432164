
export interface AxcessLoanFund {
    tranche_id: number,
    fund: string,
    commitment: number,
    drawn: number,
    undrawn: number,
    drawn_p: number,
    accrued_interest: number,
    accrued_fees: number,
    total_accruals: number,
    total_drawn_rate: number,
    percentage: number,
    base_rate: number
}

export interface Assumption {
    trancheId: number,
    type: AssumptionType
}

export enum AssumptionType {
    MATURED = 'MATURED',
    PAST_MATURITY = 'PAST_MATURITY',
    EARLY_OR_SOLD_EXT = 'EARLY_OR_SOLD_EXT',
    FUND_TRANSFER = 'FUND_TRANSFER',
    PARTIAL_REPAYMENT = 'PARTIAL_REPAYMENT',
    LOAN_EXTENSION = 'LOAN_EXTENSION',
    MATURITY_CHANGED = 'MATURITY_CHANGED',
    COMMITMENT_INCREASE = 'COMMITMENT_INCREASE'
}

export const AssumptionTypeFields = [
    {label: 'Maturity Reached', value: AssumptionType.MATURED},
    {label: 'Past Maturity', value: AssumptionType.PAST_MATURITY},
    {label: 'Repaid Early | External Sale', value: AssumptionType.EARLY_OR_SOLD_EXT},
    {label: 'Fund Transfer', value: AssumptionType.FUND_TRANSFER},
    {label: 'Partial Repayment', value: AssumptionType.PARTIAL_REPAYMENT},
    {label: 'Loan Extension', value: AssumptionType.LOAN_EXTENSION},
    {label: 'Maturity Changed', value: AssumptionType.MATURITY_CHANGED},
    {label: 'Commitment Increase', value: AssumptionType.COMMITMENT_INCREASE},
]

export enum CurrencyType {
    AUD = 'AUD',
    GBP = 'GBP',
    NZD = 'NZD',
    USD = 'USD'
}

export enum InvestmentType {
    REAL_ESTATE = 'Real Estate',
    CORPORATE = 'Corporate',
    LEVERAGED = 'Leveraged',
    EQUITY = 'Equity',
    FUND_FINANCE = 'Fund Finance',
    PROJECT_FINANCE_INFRASTRUCTURE = 'Project Finance & Infrastructure',
    STRUCTURED = 'Structured',
    OTHER = 'Other'
}

export enum Seniority {
    EQUITY = 'Equity',
    OTHER = 'OTHER',
    SENIOR = 'Senior',
    SUBORDINATED = 'Subordindated',
    SUPER_SENIOR = 'Super Senior'
}

export enum SPSector {
    COMMUNICATIONS = 'Communications',
    CONSUMER_DISCRETIONARY = 'Consumer Discretionary',
    CONSUMER_STAPLES = 'Consumer Staples',
    ENERGY = 'Energy',
    FINANCIALS = 'Financials',
    HEALTH_CARE = 'Health Care',
    INDUSTRIALS = 'Industrials',
    INFORMATION_TECHNOLOGY = 'Information Technology',
    MATERIALS = 'Materials',
    REAL_ESTATE = 'Real Estate',
    UTILITIES = 'Utilities'
}

export enum States {
    ACT = 'ACT',
    NSW = 'NSW',
    NT = 'NT',
    OFFSHORE = 'Offshore',
    QLD = 'QLD',
    SA = 'SA',
    TAS = 'TAS',
    VIC = 'VIC',
    WA = 'WA'
}

export enum TrancheTypes {
    TERM_BULLET = 'Term Bullet',
    EQUITY = 'Equity',
    REVOLVING = 'Revolving',
    CONSTRUCTION = 'Construction',
    REVOLVING_MULTI_CURRENCY = 'Revolving Multi-currency',
    CAPEX = 'Capex',
    TERM_AMORTISING = 'Term Amortising',
    GUARANTEE = 'Guarantee',
    OTHER = 'Other'
}

export interface AFRAxcessLoanCompare {
    id?: number,
    client_id: number,
    asset_id: number,
    tranche_id: number,
    apra_business_type: 'Small' | 'Medium' | 'Large',
    apra_facility_type: string,
    apra_industry: string,
    apra_lending_category: string,
    apra_purpose: string,
    asset: string,
    base_currency: CurrencyType,
    base_rate_floor: number | null,
    borrower: string,
    commitment: number,
    drawn_margin: number,
    facility_fee_rate_p: number,
    facility_fee_type: string,
    interest_type: string,
    investment_type: InvestmentType,
    margin_type: string,
    maturity: number | Date,
    pricing_type: string,
    rating_fitch: string,
    rating_mcp: string,
    rating_moodys: string,
    rating_sp: string,
    remaining_tenor_yrs: number,
    security: boolean,
    seniority_rank: Seniority,
    sp_industry: string,
    sp_industry_group: string,
    sp_sector: SPSector,
    sp_sub_industry: string,
    start_date: number | Date,
    state: States,
    tranche: string,
    tranche_type: TrancheTypes,
    funds_before: Array<AxcessLoanFund>,
    funds: Array<AxcessLoanFund>,
    allFunds: Array<string>,
    change: {
        [x: string]: number | string
    },
    removed: boolean,
    new: boolean,
    highlighted?: boolean,
    assumption?: AssumptionType
}

export interface FXRate {
    id: number,
    currency: string,
    rate: number,
    date: number | Date
}