import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AFRAxcessLoanCompare, FXRate} from "../../types/AssetFundRegisterTypes";
import {changeAFRDates, retrieveInitialAFRData} from "./afrFunction";

export interface AFRState {
    date: null | number,
    previousDate: null | number,
    dataSets: Array<number> | null,
    portfolio: Array<AFRAxcessLoanCompare> | null,
    fund: string,
    funds: Array<string> | null,
    fx: {
        previous: Array<FXRate> | null,
        current: Array<FXRate> | null,
    }
    loading: boolean,
    error: boolean
}

const initialState: AFRState = {
    date: null,
    previousDate: null,
    dataSets: null,
    portfolio: null,
    fund: 'ALL',
    funds: null,
    fx: {
        previous: null,
        current: null
    },
    loading: false,
    error: false
}

export const afrSlice = createSlice(({
    name: 'afr',
    initialState,
    reducers: {
        selectFund: (state, action: PayloadAction<string>) => {
            state.fund = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            // Initial Load
            .addCase(retrieveInitialAFRData.pending, (state) => {
                state.loading = true;
            })
            .addCase(retrieveInitialAFRData.fulfilled, (state, action) => {
                state.loading = false;
                state.date = action.payload.date;
                state.previousDate = action.payload.previousDate;
                state.dataSets = action.payload.dataSets;
                state.portfolio = action.payload.portfolio;
                state.funds = action.payload.funds;
                state.fx = action.payload.fx;
            })
            .addCase(retrieveInitialAFRData.rejected, (state) => {
                state.loading = false;
                state.error = true
            })
            // Date Change
            .addCase(changeAFRDates.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeAFRDates.fulfilled, (state, action) => {
                state.loading = false;
                state.date = action.payload.date;
                state.previousDate = action.payload.previousDate;
                state.portfolio = action.payload.portfolio;
                state.funds = action.payload.funds;
                state.fx = action.payload.fx;
            })
            .addCase(changeAFRDates.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state.date = null;
                state.previousDate = null;
                state.portfolio = null;
                state.funds = null;
            })
    }
}))

export const {
    selectFund
} = afrSlice.actions;

export default afrSlice.reducer;